import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerTeaching from '../components/BannerTeaching'

import pic01 from '../assets/images/capstone_gatech.jpg'
import pic02 from '../assets/images/mechatronics.jpg'
import pic03 from '../assets/images/3dprinting.jpg'
import pic04 from '../assets/images/dinamica_maquinaria.jpg'
import pic05 from '../assets/images/fundamentos_exp_01.jpg'
import pic06 from '../assets/images/hydraulic_pump.jpg'

import GoToTop from '../components/ui/GoToTop'

const Teaching = (props) => (
    <Layout>
        <Helmet>
            <title>Teaching - Jonathan Camargo</title>
            <meta name="description" content="Jonathan Camargo Teaching in Uniandes and GeorgiaTech" />
        </Helmet>

        <BannerTeaching />
        <div id="main">      
            <section id="one">
                    <div className="inner">
                        <header className="major">                            
                        </header>
                        <p>I am passionate about teaching, and I firmly believe that research productivity should not hurt the teaching quality. I like finding new ways to motivate my students to enjoy learning and getting involved in more advanced topics.
                        </p>
                        <p>
                        These are the courses that I have taught. My courses have covered topics in cad/cam, system dynamics, experimentation, and more. I want to develop new courses to share the skills I learned in my Ph.D., providing the students with more opportunities to learn about machine learning, gait analysis, and wearable robotics.
                        </p>
                        
                    </div>
            </section>

        <section id="two" className="spotlights">                   
                <section>
                    <Link to="/#" className="image">
                        <img src={pic01} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Capstone Design</h3>
                            </header>
                            <p>As part of a teaching practicum, I lectured the Capstone Design. This course is a culminating milestone offered to undergraduate students in the School of Mechanical Engineering at Gatech. Students work in teams to design and validate solutions to real-world problems. At the end of each semester, students showcase their efforts at the Capstone Design Expo.
                            </p>
                            {/* <ul className="actions">
                                <li><Link to="/#" className="button">Learn more</Link></li>
                            </ul>                             */}
                        </div>
                    </div>
                </section> 
                <section>
                    <Link to="/#" className="image">
                        <img src={pic02} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Mechatronics</h3>
                            </header>
                            <p>At GeorgiaTech, I was the TA for this course that introduces students to microcontrollers and hardware control for robotic devices. Course modules focus on microcontroller design and programming, mechanical actuators, sensors, feedback control, and system modeling. Through these modules, students develop a strong fundamental understanding of microcontroller programming, analog-to-digital conversion, control of mechanical and thermal systems, feedback concepts, and embedded software development. Through 10 lab exercises and a final project, students gain practical experience designing and constructing all aspects of mechatronics systems.</p>
                            {/*<ul className="actions">
                                <li><Link to="/#" className="button">Learn more</Link></li>
                        </ul>*/}
                        </div>
                    </div>
                </section> 
                <section>
                    <Link to="/#" className="image">
                        <img src={pic03} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>3D printing</h3>
                            </header>
                            <p> I designed and taught the 3D Printing course at Uniandes. This course presents a complete view of the digital fabrication process. It analyzes the advantages and limitations of different technologies of additive manufacturing. Initially, the students develop the skills and background to use 3D printing to design and build parts to become informed users of the process. During the second part of the course, the components and operation of a 3D printer are discussed through hands-on projects. Through these projects, the students can design and build their very own 3D printing machines.</p>
                            {/*<ul className="actions">
                                <li><Link to="/#" className="button">Learn more</Link></li>
                        </ul>*/}
                        </div>
                    </div>
                </section>
                <section>
                    <Link to="/#" className="image">
                        <img src={pic04} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Dynamics of Machinery</h3>
                            </header>
                            <p>This course covers the analysis of mechanisms and machines from the kinematics and dynamics of motion. The students learn to formulate and evaluate the equations of motions to determine the forces and power required for the machines' operation. In addition to the analytic formulation of the equations of motion, the course uses modern CAD software with dynamics analysis.
                                </p>                                
                            {/*<ul className="actions">
                                <li><Link to="/#" className="button">Learn more</Link></li>
                    </ul>*/}
                        </div>
                    </div>
                </section>
                <section>
                    <Link to="/#" className="image">
                        <img src={pic05} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Fundamentals of Experimentation</h3>
                            </header>
                            <p>This course trains the students in the planning and conduction of experiments. Aside from the classic statistics content, I included data acquisition using open-source tools. With this, the students learn to build custom sensing solutions that they could adapt to multiple settings. </p>
                            {/*<ul className="actions">
                                <li><Link to="/#" className="button">Learn more</Link></li>
                            </ul>*/}
                        </div>
                    </div>
                </section>
                <section>
                    <Link to="/#" className="image">
                        <img src={pic06} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Graphic Design in Engineering</h3>
                            </header>
                            <p>In this course, the students get the fundamentals skills for visual communication in mechanical engineering. It covers basic sketching techniques and a heavy focus on CAD software to enable the students to communicate their ideas in quick sketching and with the details of a 3D model and drafting.
                            </p>
                            {/*<ul className="actions">
                                <li><Link to="/#" className="button">Learn more</Link></li>
                            </ul>*/}
                        </div>
                    </div>
                </section>
            </section>       

            <GoToTop></GoToTop>

        </div>

    </Layout>
)

export default Teaching